.Background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: url("https://2x20fitness.com/wp-content/uploads/2018/03/BG.png");
    background: url("https://2x20fitness.com/wp-content/uploads/2018/03/cta.jpg");
    background-position: 50% 50%;
    background-size: cover;
    z-index: -1;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.35);
    }
}