.RoutineExercise {
    .form-control-label {
        font-size: 1.3rem;
    }
    .form-group {
        .form-control {
            min-height: 70px;
        }
        #inputReps {
            padding-left: 1.6em;
        }
    }
    .messaging {
        font-size: 1.3rem;
    }
}