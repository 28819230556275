.Header {
    background-color: rgba(0, 0, 0, 0.95);

    .row {
        align-items: flex-end;
    }

    .logo {
        height: 55px;
    }

    h1 {
        line-height: 1.5em;
        padding: 0 0 2px;
        font-size: 28px;
        margin-bottom: -.5em;
    }

    .machine {
        flex-grow: 1.4;
    }
}