@import url('https://fonts.googleapis.com/css?family=Mina:400,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);
$pagination-bg: #444;
$pagination-hover-bg: #adb5bd;
$pagination-disabled-bg: #222;
$pagination-active-bg: #adb5bd;
@import 'bootswatch/dist/darkly/_variables.scss';
@import 'bootstrap/scss/bootstrap.scss';
@import 'bootswatch/dist/darkly/_bootswatch.scss';
@import './styles/vars';

#root,
body,
html {
    display: inline;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    height: 100%;
    font-family: 'Open Sans', sans-serif !important;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;
}

input,
input:after,
input:before {
    user-select: initial !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Mina', sans-serif !important;
    margin: 0;
    padding: 0;
    line-height: 2rem;
}

h1 {
    font-size: 38px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 20px;
}

.card {
    height: 100%;
}

.dropdown.show {
    .dropdown-menu {
        display: block;
    }
}

.text-red {
    color: $colorRedThin;
}

.text-warning {
    color: $colorYellow !important;
    font-weight: bold;
    font-size: 1rem;
}

.btn {
    box-sizing: border-box;
    border-radius: 0;
    font-size: 1rem;
    font-weight: bold;

    &:active,
    &:focus {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
    }

    &.btn-custom-primary {
        color: #fff;
        background: $colorRed !important;
        border: none;
        padding: 12px 32px;
    }

    &.btn-custom-secondary {
        color: #fff;
        background: #000;
        border: 2px solid $colorRedThin !important;
        padding: 10px 30px;

        &.alt {
            border: 2px solid $colorYellow !important;
        }
    }
}

.form-control {
    border-radius: 0;
    border: none;
    background: #000;
    color: #fff;
    font-size: 30px;
    padding: 15px 30px;
    appearance: none;
		line-height: 1.5rem;
		min-height: 70px;

    &:focus {
        border: none;
        outline: none;
        box-shadow: none;
        background: #000;
        color: #fff;
    }

    &.alt {
        border: 2px solid $colorWhite;
    }
}

select {
    height: 70px !important;
    text-align-last: center;
}

.form-inline {
    .form-control {
        padding: 1px 20px;
        font-size: 28px;
        margin-right: 1.5rem;
    }
}

.btn.exercise-increment {
    display: block;
    text-align: center;
    width: 33%;
    margin-left: 33%;
    font-size: 1.5rem;
    padding: 5px;
    background: #000 !important;
    border: 2px solid $colorRedThin !important;
    margin: 0;
    width: 100%;

    &.top {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }

    &.bottom {
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
    }
}