@import '../../styles/vars';
.ChartRoutine {
    min-height: 40px;
    >td {
        padding-bottom: 1em;
    }
    .date {
        white-space: nowrap;
        padding-right: 1em;
    }
    .exercises {
        width: 99%;
        .exercise {
            position: relative;
            display: inline-block;
            height: 100%;
            min-height: 30px;
            cursor: pointer;
            .filler {
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% - 2px);
                height: 100%;
                background: $colorRed;
            }
        }
    }
    .total {
        white-space: nowrap;
        padding-left: 1em;
        text-align: right;
    }
}

.tooltip {
    pointer-events: none;
}

.tooltip-inner {
    background: $colorRed;
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
    margin-left: -3px;
    content: "";
    border-width: 5px 5px 0;
    border-top-color: $colorRed;
}

.tooltip.bs-tooltip-top {
    top: 10px !important;
    filter: drop-shadow(0 0 0.25rem black);
}