@import '../../styles/vars';

.GymLocation {
    position: fixed;
    bottom: 0;
    left: 0;
    color: $colorRed;
    font-size: 0.65rem;
    padding: 0 0.5em;
    background: #000;
    font-weight: bold;
}